define("admin-lte-ember/components/alte/layout", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="wrapper">
    {{yield}}
  </div>
  
  */
  {
    "id": "416JYp2m",
    "block": "[[[10,0],[14,0,\"wrapper\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "admin-lte-ember/components/alte/layout.hbs",
    "isStrictMode": false
  });
  class AlteLayoutComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
      if (args.layout) {
        document.body.classList.add(args.layout);
      }
    }
    willDestroy() {
      if (this.args.layout) {
        document.body.classList.remove(this.args.layout);
      }
    }
  }
  _exports.default = AlteLayoutComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AlteLayoutComponent);
});