define("ember-bootstrap/utils/deprecate-subclassing", ["exports", "@ember/debug", "@ember/runloop"], function (_exports, _debug, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = deprecateSubclassing;
  function deprecateSubclassing(target) {
    if (false /* DEBUG */) {
      const wrapperClass = class extends target {
        constructor() {
          super(...arguments);
          // we need to delay the deprecation check, as the __ember-bootstrap_subclass class field will only be set *after* the constructor
          (0, _runloop.next)(() => {
            (false && !(
            // the `__ember-bootstrap_subclass` flag is an escape hatch for "privileged" addons like validations addons that currently still have to rely on subclassing
            wrapperClass === this.constructor || this['__ember-bootstrap_subclass'] === true) && (0, _debug.deprecate)(`Extending from ember-bootstrap component classes is not supported, and might break anytime. Detected subclassing of <Bs${target.name}> component.`, wrapperClass === this.constructor || this['__ember-bootstrap_subclass'] === true, {
              id: `ember-bootstrap.subclassing#${target.name}`,
              until: '5.0.0',
              since: '4.0.0',
              for: 'ember-bootstrap'
            }));
          });
        }
      };
      return wrapperClass;
    }
  }
});