define("ember-bootstrap/components/bs-form/element/label", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "ember-bootstrap/utils/default-decorator", "@ember/utils", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _defaultDecorator, _utils, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    {{#if (has-block)}}
      <label>
        {{yield}}
        {{@label}}
      </label>
    {{else}}
      <label class="control-label {{if this.invisibleLabel (if (macroCondition (macroGetOwnConfig "isBS5")) "visually-hidden" "sr-only")}} {{this.labelClass}}" for={{@formElementId}}>{{@label}}</label>
    {{/if}}
  {{else}}
    <label class="{{if (macroCondition (macroGetOwnConfig "isBS5")) "form-label"}} {{if this.invisibleLabel (if (macroCondition (macroGetOwnConfig "isBS5")) "visually-hidden" "sr-only")}} {{this.labelClass}} {{if this.isHorizontalAndNotCheckbox "col-form-label"}} {{if this.isCheckbox "form-check-label"}} {{if (bs-eq "switch" @controlType) (if (macroCondition (macroGetOwnConfig "isBS5")) "form-check-label" "custom-control-label")}} {{this.sizeClass}}" for={{@formElementId}}>
      {{#if (has-block)}}
        {{yield}}
      {{/if}}
      {{@label}}
    </label>
  {{/if}}
  
  */
  {
    "id": "amdR5D44",
    "block": "[[[1,\"  \"],[10,\"label\"],[15,0,[29,[[27],\" \",[52,[30,0,[\"invisibleLabel\"]],\"sr-only\"],\" \",[30,0,[\"labelClass\"]],\" \",[52,[30,0,[\"isHorizontalAndNotCheckbox\"]],\"col-form-label\"],\" \",[52,[30,0,[\"isCheckbox\"]],\"form-check-label\"],\" \",[52,[28,[37,1],[\"switch\",[30,1]],null],\"custom-control-label\"],\" \",[30,0,[\"sizeClass\"]]]]],[15,\"for\",[30,2]],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@controlType\",\"@formElementId\",\"@label\",\"&default\"],false,[\"if\",\"bs-eq\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/label.hbs",
    "isStrictMode": false
  });
  /**
  
   @class FormElementLabel
   @namespace Components
   @extends Ember.Component
   @private
   */
  let FormElementLabel = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('isHorizontal', 'isCheckbox'), _dec3 = (0, _object.computed)('size', 'isHorizontal'), _dec4 = (0, _computed.equal)('controlType', 'checkbox').readOnly(), _dec5 = (0, _computed.equal)('formLayout', 'horizontal').readOnly(), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementLabel extends _component.default {
    constructor() {
      super(...arguments);
      /**
       * @property label
       * @type string
       * @public
       */
      /**
       * @property invisibleLabel
       * @type boolean
       * @public
       */
      _initializerDefineProperty(this, "invisibleLabel", _descriptor, this);
      /**
       * [BS4 only] Property for size styling, set to 'lg', 'sm'
       *
       * @property size
       * @type String
       * @public
       */
      _initializerDefineProperty(this, "size", _descriptor2, this);
      /**
       * @property formElementId
       * @type {String}
       * @public
       */
      /**
       * @property labelClass
       * @type {String}
       * @public
       */
      /**
       * The form layout used for the markup generation (see http://getbootstrap.com/css/#forms):
       *
       * * 'horizontal'
       * * 'vertical'
       * * 'inline'
       *
       * Defaults to the parent `form`'s `formLayout` property.
       *
       * @property formLayout
       * @type string
       * @default 'vertical'
       * @public
       */
      _initializerDefineProperty(this, "formLayout", _descriptor3, this);
      /**
       * The type of the control widget.
       * Supported types:
       *
       * * 'text'
       * * 'checkbox'
       * * 'textarea'
       * * any other type will use an input tag with the `controlType` value as the type attribute (for e.g. HTML5 input
       * types like 'email'), and the same layout as the 'text' type
       *
       * @property controlType
       * @type string
       * @default 'text'
       * @public
       */
      _initializerDefineProperty(this, "controlType", _descriptor4, this);
      /**
       * Indicates whether the type of the control widget equals `checkbox`
       *
       * @property isCheckbox
       * @type boolean
       * @private
       */
      _initializerDefineProperty(this, "isCheckbox", _descriptor5, this);
      /**
       * Indicates whether the form type equals `horizontal`
       *
       * @property isHorizontal
       * @type boolean
       * @private
       */
      _initializerDefineProperty(this, "isHorizontal", _descriptor6, this);
    }
    get isHorizontalAndNotCheckbox() {
      return this.isHorizontal && !this.isCheckbox;
    }
    get sizeClass() {
      if (!this.isHorizontal) {
        return undefined;
      }
      let size = this.size;
      return (0, _utils.isBlank)(size) ? null : `col-form-label-${size}`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "invisibleLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "isHorizontalAndNotCheckbox", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "isHorizontalAndNotCheckbox"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sizeClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "sizeClass"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "formLayout", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'vertical';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "controlType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'text';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isCheckbox", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isHorizontal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = FormElementLabel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementLabel);
});