define("admin-lte-ember/components/alte/card/content", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @collapsable}}
    <BsCollapse @collapsed={{this.collapsed}}>
      {{yield}}
    </BsCollapse>
  {{else}}
    {{yield}}
  {{/if}}
  
  */
  {
    "id": "b3hUhl/8",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@collapsed\"],[[30,0,[\"collapsed\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]]]],[\"@collapsable\",\"&default\"],false,[\"if\",\"bs-collapse\",\"yield\"]]",
    "moduleName": "admin-lte-ember/components/alte/card/content.hbs",
    "isStrictMode": false
  });
  class AlteCardContent extends _component2.default {
    get collapsed() {
      return this.args.collapsed;
    }
  }
  _exports.default = AlteCardContent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AlteCardContent);
});