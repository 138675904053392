define("ember-bootstrap/helpers/bs-contains", ["exports", "@ember/component/helper", "@ember/array"], function (_exports, _helper, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bsContains = bsContains;
  _exports.default = void 0;
  function bsContains(params /* , hash*/) {
    return (0, _array.isArray)(params[0]) ? (0, _array.A)(params[0]).includes(params[1]) : false;
  }
  var _default = (0, _helper.helper)(bsContains);
  _exports.default = _default;
});