define("ember-resources/-private/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_THUNK = void 0;
  _exports.normalizeThunk = normalizeThunk;
  _exports.proxyClass = proxyClass;
  /* eslint-disable @typescript-eslint/ban-types */

  const DEFAULT_THUNK = () => [];
  _exports.DEFAULT_THUNK = DEFAULT_THUNK;
  function normalizeThunk(thunk) {
    let args = thunk();
    if (Array.isArray(args)) {
      return {
        named: {},
        positional: args
      };
    }
    if (!args) {
      return {
        named: {},
        positional: []
      };
    }

    /**
     * Hopefully people aren't using args named "named"
     */
    if ('positional' in args || 'named' in args) {
      return args;
    }
    return {
      named: args,
      positional: []
    };
  }
  function proxyClass(target) {
    return new Proxy(target, {
      get(target, key) {
        const instance = target.value;
        const value = Reflect.get(instance, key, instance);
        return typeof value === 'function' ? value.bind(instance) : value;
      },
      ownKeys(target) {
        return Reflect.ownKeys(target.value);
      },
      getOwnPropertyDescriptor(target, key) {
        return Reflect.getOwnPropertyDescriptor(target.value, key);
      }
    });
  }
});