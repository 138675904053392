define("ember-changeset-validations/helpers/changeset", ["exports", "@ember/component/helper", "ember-changeset/helpers/changeset"], function (_exports, _helper, _changeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;
  function changeset(_ref, options) {
    let [obj, validations] = _ref;
    return (0, _changeset.changeset)([obj, validations], options);
  }
  var _default = (0, _helper.helper)(changeset);
  _exports.default = _default;
});