define("ember-bootstrap/components/bs-contextual-help", ["exports", "@glimmer/component", "@ember/array", "@ember/object", "@ember/runloop", "ember-bootstrap/utils/transition-end", "ember-bootstrap/utils/dom", "ember-bootstrap/utils/decorators/uses-transition", "@ember/debug", "ember", "ember-bootstrap/utils/decorators/arg", "@glimmer/tracking", "ember-ref-bucket"], function (_exports, _component, _array, _object, _runloop, _transitionEnd, _dom, _usesTransition, _debug, _ember, _arg, _tracking, _emberRefBucket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const HOVERSTATE_NONE = 'none';
  const HOVERSTATE_IN = 'in';
  const HOVERSTATE_OUT = 'out';
  function noop() {}

  /**
    @class ContextualHelp
    @namespace Components
    @extends Glimmer.Component
    @private
  */
  let ContextualHelp = (_dec = (0, _usesTransition.default)('fade'), _dec2 = (0, _emberRefBucket.ref)('overlayElement'), (_class = class ContextualHelp extends _component.default {
    constructor() {
      super(...arguments);
      /**
       * @property title
       * @type string
       * @public
       */
      /**
       * How to position the tooltip/popover - top | bottom | left | right
       *
       * @property title
       * @type string
       * @default 'top'
       * @public
       */
      _initializerDefineProperty(this, "placement", _descriptor, this);
      /**
       * By default it will dynamically reorient the tooltip/popover based on the available space in the viewport. For
       * example, if `placement` is "left", the tooltip/popover will display to the left when possible, otherwise it will
       * display right. Set to `false` to force placement according to the `placement` property
       *
       * @property autoPlacement
       * @type boolean
       * @default true
       * @public
       */
      _initializerDefineProperty(this, "autoPlacement", _descriptor2, this);
      /**
       * You can programmatically show the tooltip/popover by setting this to `true`
       *
       * @property visible
       * @type boolean
       * @default false
       * @public
       */
      _initializerDefineProperty(this, "visible", _descriptor3, this);
      /**
       * @property inDom
       * @type boolean
       * @private
       */
      _initializerDefineProperty(this, "_inDom", _descriptor4, this);
      /**
       * Set to false to disable fade animations.
       *
       * @property fade
       * @type boolean
       * @default true
       * @public
       */
      _initializerDefineProperty(this, "fade", _descriptor5, this);
      /**
       * Used to apply Bootstrap's visibility class
       *
       * @property showHelp
       * @type boolean
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "showHelp", _descriptor6, this);
      /**
       * Delay showing and hiding the tooltip/popover (ms). Individual delays for showing and hiding can be specified by using the
       * `delayShow` and `delayHide` properties.
       *
       * @property delay
       * @type number
       * @default 0
       * @public
       */
      _initializerDefineProperty(this, "delay", _descriptor7, this);
      /**
       * Delay showing the tooltip/popover. This property overrides the general delay set with the `delay` property.
       *
       * @property delayShow
       * @type number
       * @default 0
       * @public
       */
      _initializerDefineProperty(this, "delayShow", _descriptor8, this);
      /**
       * Delay hiding the tooltip/popover. This property overrides the general delay set with the `delay` property.
       *
       * @property delayHide
       * @type number
       * @default 0
       * @public
       */
      _initializerDefineProperty(this, "delayHide", _descriptor9, this);
      /**
       * The duration of the fade transition
       *
       * @property transitionDuration
       * @type number
       * @default 150
       * @public
       */
      _initializerDefineProperty(this, "transitionDuration", _descriptor10, this);
      /**
       * Keeps the tooltip/popover within the bounds of this element when `autoPlacement` is true. Can be any valid CSS selector.
       *
       * @property viewportSelector
       * @type string
       * @default 'body'
       * @see viewportPadding
       * @see autoPlacement
       * @public
       */
      _initializerDefineProperty(this, "viewportSelector", _descriptor11, this);
      /**
       * Take a padding into account for keeping the tooltip/popover within the bounds of the element given by `viewportSelector`.
       *
       * @property viewportPadding
       * @type number
       * @default 0
       * @see viewportSelector
       * @see autoPlacement
       * @public
       */
      _initializerDefineProperty(this, "viewportPadding", _descriptor12, this);
      _defineProperty(this, "_parentFinder", self.document ? self.document.createTextNode('') : '');
      /**
       * The DOM element that triggers the tooltip/popover. By default it is the parent element of this component.
       * You can set this to any CSS selector to have any other element trigger the tooltip/popover.
       * With the special value of "parentView" you can attach the tooltip/popover to the parent component's element.
       *
       * @property triggerElement
       * @type string | null
       * @public
       */
      _initializerDefineProperty(this, "triggerElement", _descriptor13, this);
      /**
       * The event(s) that should trigger the tooltip/popover - click | hover | focus.
       * You can set this to a single event or multiple events, given as an array or a string separated by spaces.
       *
       * @property triggerEvents
       * @type array|string
       * @default 'hover focus'
       * @public
       */
      _initializerDefineProperty(this, "triggerEvents", _descriptor14, this);
      /**
       * Current hover state, 'in', 'out' or null
       *
       * @property hoverState
       * @type number
       * @private
       */
      _defineProperty(this, "hoverState", HOVERSTATE_NONE);
      /**
       * Current state for events
       */
      _defineProperty(this, "hover", false);
      _defineProperty(this, "focus", false);
      _defineProperty(this, "click", false);
      /**
       * Ember.run timer
       *
       * @property timer
       * @private
       */
      _defineProperty(this, "timer", null);
      /**
       * Use CSS transitions?
       *
       * @property usesTransition
       * @type boolean
       * @readonly
       * @private
       */
      _initializerDefineProperty(this, "usesTransition", _descriptor15, this);
      /**
       * The DOM element of the overlay element.
       *
       * @property overlayElement
       * @type object
       * @readonly
       * @private
       */
      _initializerDefineProperty(this, "overlayElement", _descriptor16, this);
    }
    get inDom() {
      return this._inDom ?? !!(this.visible && this.triggerTargetElement);
    }
    set inDom(value) {
      if (this._inDom === value) {
        return;
      }
      this._inDom = value;
    }
    /**
     * The DOM element of the arrow element.
     *
     * @property arrowElement
     * @type object
     * @readonly
     * @private
     */
    /**
     * The wormhole destinationElement
     *
     * @property destinationElement
     * @type object
     * @readonly
     * @private
     */
    get destinationElement() {
      return (0, _dom.getDestinationElement)(this);
    }

    /**
     * The DOM element of the viewport element.
     *
     * @property viewportElement
     * @type object
     * @readonly
     * @private
     */
    get viewportElement() {
      return document.querySelector(this.viewportSelector);
    }
    /**
     * @method getTriggerTargetElement
     * @private
     */
    getTriggerTargetElement() {
      let triggerElement = this.triggerElement;
      let el;
      if (!triggerElement) {
        el = this._parent;
      } else if (triggerElement === 'parentView') {
        (false && !(false) && (0, _debug.deprecate)('@triggerElement="parentView" is deprecated. Please remove the @triggerElement argument to target the parent DOM element, or use a CSS selector', false, {
          id: `ember-bootstrap.contextual-help.parent-view`,
          until: '5.0.0',
          since: '4.2.0',
          for: 'ember-bootstrap'
        }));
        el = (0, _dom.getParentView)(this._parent);
      } else {
        el = document.querySelector(triggerElement);
      }
      (false && !(el) && (0, _debug.assert)('Could not find trigger element for tooltip/popover component', el));
      return el;
    }
    get _triggerEvents() {
      let events = this.triggerEvents;
      if (!(0, _array.isArray)(events)) {
        events = events.split(' ');
      }
      return events.map(event => {
        switch (event) {
          case 'hover':
            return ['mouseenter', 'mouseleave'];
          case 'focus':
            return ['focusin', 'focusout'];
          default:
            return event;
        }
      });
    }

    /**
     * If true component will render in place, rather than be wormholed.
     *
     * @property renderInPlace
     * @type boolean
     * @default false
     * @public
     */

    /**
     * @property _renderInPlace
     * @type boolean
     * @private
     */
    get _renderInPlace() {
      return this.args.renderInPlace || !this.destinationElement;
    }
    get shouldShowHelp() {
      return this.hover || this.focus || this.click;
    }
    /**
     * This action is called immediately when the tooltip/popover is about to be shown.
     *
     * @event onShow
     * @public
     */

    /**
     * This action will be called when the tooltip/popover has been made visible to the user (will wait for CSS transitions to complete).
     *
     * @event onShown
     * @public
     */

    /**
     * This action is called immediately when the tooltip/popover is about to be hidden.
     *
     * @event onHide
     * @public
     */

    /**
     * This action is called when the tooltip/popover has finished being hidden from the user (will wait for CSS transitions to complete).
     *
     * @event onHidden
     * @public
     */
    /**
     * Called when a show event has been received
     *
     * @method enter
     * @param e
     * @private
     */
    enter(e) {
      if (e) {
        let eventType = e.type === 'focusin' ? 'focus' : 'hover';
        this[eventType] = true;
      }
      if (this.showHelp || this.hoverState === HOVERSTATE_IN) {
        this.hoverState = HOVERSTATE_IN;
        return;
      }
      (0, _runloop.cancel)(this.timer);
      this.hoverState = HOVERSTATE_IN;
      if (!this.delayShow) {
        return this.show();
      }
      this.timer = (0, _runloop.later)(this, function () {
        if (this.hoverState === HOVERSTATE_IN) {
          this.show();
        }
      }, this.delayShow);
    }

    /**
     * Called when a hide event has been received
     *
     * @method leave
     * @param e
     * @private
     */
    leave(e) {
      if (e) {
        let eventType = e.type === 'focusout' ? 'focus' : 'hover';
        this[eventType] = false;
      }
      if (this.shouldShowHelp) {
        return;
      }
      (0, _runloop.cancel)(this.timer);
      this.hoverState = HOVERSTATE_OUT;
      if (!this.delayHide) {
        return this.hide();
      }
      this.timer = (0, _runloop.later)(() => {
        if (this.hoverState === HOVERSTATE_OUT) {
          this.hide();
        }
      }, this.delayHide);
    }

    /**
     * Called for a click event
     *
     * @method toggle
     * @private
     */
    toggle() {
      this.click = !this.click;
      if (this.shouldShowHelp) {
        this.enter();
      } else {
        this.leave();
      }
    }

    /**
     * Show the tooltip/popover
     *
     * @method show
     * @private
     */
    show() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      if (false === this.args.onShow?.(this)) {
        return;
      }
      this.inDom = true;
      (0, _runloop.schedule)('afterRender', this, this._show);
    }
    _show() {
      let skipTransition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.showHelp = true;

      // If this is a touch-enabled device we add extra
      // empty mouseover listeners to the body's immediate children;
      // only needed because of broken event delegation on iOS
      // https://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html

      // See https://github.com/twbs/bootstrap/pull/22481
      if ('ontouchstart' in document.documentElement) {
        let {
          children
        } = document.body;
        for (let i = 0; i < children.length; i++) {
          children[i].addEventListener('mouseover', noop);
        }
      }
      let tooltipShowComplete = () => {
        if (this.isDestroyed) {
          return;
        }
        let prevHoverState = this.hoverState;
        this.args.onShown?.(this);
        this.hoverState = HOVERSTATE_NONE;
        if (prevHoverState === HOVERSTATE_OUT) {
          this.leave();
        }
      };
      if (skipTransition === false && this.usesTransition) {
        (0, _transitionEnd.default)(this.overlayElement, this.transitionDuration).then(tooltipShowComplete);
      } else {
        tooltipShowComplete();
      }
    }

    /**
     * Position the tooltip/popover's arrow
     *
     * @method replaceArrow
     * @param delta
     * @param dimension
     * @param isVertical
     * @private
     */
    replaceArrow(delta, dimension, isVertical) {
      let el = this.arrowElement;
      el.style[isVertical ? 'left' : 'top'] = `${50 * (1 - delta / dimension)}%`;
      el.style[isVertical ? 'top' : 'left'] = null;
    }

    /**
     * Hide the tooltip/popover
     *
     * @method hide
     * @private
     */
    hide() {
      if (this.isDestroyed) {
        return;
      }
      if (false === this.args.onHide?.(this)) {
        return;
      }
      let tooltipHideComplete = () => {
        if (this.isDestroyed) {
          return;
        }
        if (this.hoverState !== HOVERSTATE_IN) {
          this.inDom = false;
        }
        this.args.onHidden?.(this);
      };
      this.showHelp = false;

      // if this is a touch-enabled device we remove the extra
      // empty mouseover listeners we added for iOS support
      if ('ontouchstart' in document.documentElement) {
        let {
          children
        } = document.body;
        for (let i = 0; i < children.length; i++) {
          children[i].removeEventListener('mouseover', noop);
        }
      }
      if (this.usesTransition) {
        (0, _transitionEnd.default)(this.overlayElement, this.transitionDuration).then(tooltipHideComplete);
      } else {
        tooltipHideComplete();
      }
      this.hoverState = HOVERSTATE_NONE;
    }

    /**
     * @method addListeners
     * @private
     */
    addListeners() {
      let target = this.triggerTargetElement;
      this._triggerEvents.forEach(event => {
        if ((0, _array.isArray)(event)) {
          let [inEvent, outEvent] = event;
          target.addEventListener(inEvent, this._handleEnter);
          target.addEventListener(outEvent, this._handleLeave);
        } else {
          target.addEventListener(event, this._handleToggle);
        }
      });
    }

    /**
     * @method removeListeners
     * @private
     */
    removeListeners() {
      try {
        let target = this.triggerTargetElement;
        this._triggerEvents.forEach(event => {
          if ((0, _array.isArray)(event)) {
            let [inEvent, outEvent] = event;
            target.removeEventListener(inEvent, this._handleEnter);
            target.removeEventListener(outEvent, this._handleLeave);
          } else {
            target.removeEventListener(event, this._handleToggle);
          }
        });
      } catch (e) {} // eslint-disable-line no-empty
    }

    /**
     * @method handleTriggerEvent
     * @private
     */
    handleTriggerEvent(handler, e) {
      let overlayElement = this.overlayElement;
      if (overlayElement && overlayElement.contains(e.target)) {
        return;
      }
      return handler.call(this, e);
    }
    _handleEnter(e) {
      this.handleTriggerEvent(this.enter, e);
    }
    _handleLeave(e) {
      this.handleTriggerEvent(this.leave, e);
    }
    _handleToggle(e) {
      this.handleTriggerEvent(this.toggle, e);
    }
    close() {
      // Make sure our click state is off, otherwise the next click would
      // close the already-closed tooltip/popover. We don't need to worry
      // about this for hover/focus because those aren't "stateful" toggle
      // events like click.
      this.click = false;
      this.hide();
    }
    setup() {
      if (typeof FastBoot !== 'undefined') {
        // ember-render-helpers calls this also in FastBoot, so guard against this
        return;
      }
      let parent = this._parentFinder.parentNode;
      // In the rare case of using FastBoot w/ rehydration, the parent finder TextNode rendered by FastBoot will be reused,
      // so our own instance on the component is not rendered, only exists here as detached from DOM and thus has no parent.
      // In this case we try to use Ember's private API as a fallback.
      // Related: https://github.com/emberjs/rfcs/issues/168
      if (!parent) {
        try {
          parent = _ember.default.ViewUtils.getViewBounds(this).parentElement;
        } catch (e) {
          // we catch the possibly broken private API call, the component can still work if the trigger element is defined
          // using a CSS selector.
        }
      }
      this._parent = parent;

      // Look for target element after rendering has finished, in case the target DOM element is rendered *after* us
      // see https://github.com/kaliber5/ember-bootstrap/issues/1329
      (0, _runloop.schedule)('afterRender', () => {
        this.triggerTargetElement = this.getTriggerTargetElement();
        this.addListeners();
        if (this.visible) {
          (0, _runloop.next)(this, this.show, true);
        }
      });
    }
    showOrHide(visible) {
      if (visible) {
        this.show();
      } else {
        this.hide();
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.removeListeners();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "placement", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'top';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "autoPlacement", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "visible", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_inDom", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fade", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showHelp", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.visible;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "delay", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "delayShow", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.delay ?? 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "delayHide", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.delay ?? 0;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "transitionDuration", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "viewportSelector", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'body';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "viewportPadding", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "triggerElement", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "triggerEvents", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'hover focus';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "usesTransition", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "overlayElement", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_handleEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_handleEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_handleLeave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_handleLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_handleToggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_handleToggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showOrHide", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showOrHide"), _class.prototype)), _class));
  _exports.default = ContextualHelp;
});