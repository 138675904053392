define("ember-bootstrap/helpers/bs-eq", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.eq = eq;
  function eq(params) {
    return params[0] === params[1];
  }
  var _default = (0, _helper.helper)(eq);
  _exports.default = _default;
});