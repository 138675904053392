define("ember-bootstrap/utils/dom", ["exports", "@ember/application", "require", "@ember/debug"], function (_exports, _application, _require, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findElemementByIdInShadowDom = findElemementByIdInShadowDom;
  _exports.findElementById = findElementById;
  _exports.getDOM = getDOM;
  _exports.getDestinationElement = getDestinationElement;
  _exports.getParentView = getParentView;
  /*
   * Implement some helpers methods for interacting with the DOM,
   * be it Fastboot's SimpleDOM or the browser's version.
   *
   * Credit to https://github.com/yapplabs/ember-wormhole, from where this has been shamelessly stolen.
   */

  function childNodesOfElement(element) {
    let children = [];
    let child = element.firstChild;
    while (child) {
      children.push(child);
      child = child.nextSibling;
    }
    return children;
  }
  function findElementById(doc, id) {
    if (doc.getElementById) {
      return doc.getElementById(id);
    }
    let nodes = childNodesOfElement(doc);
    let node;
    while (nodes.length) {
      node = nodes.shift();
      if (node.getAttribute && node.getAttribute('id') === id) {
        return node;
      }
      nodes = childNodesOfElement(node).concat(nodes);
    }
  }

  // Private Ember API usage. Get the dom implementation used by the current
  // renderer, be it native browser DOM or Fastboot SimpleDOM
  function getDOM(context) {
    let {
      renderer
    } = context;
    if (!renderer?._dom) {
      // pre glimmer2
      let container = _application.getOwner ? (0, _application.getOwner)(context) : context.container;
      let documentService = container.lookup('service:-document');
      if (documentService) {
        return documentService;
      }
      renderer = container.lookup('renderer:-dom');
    }
    if (renderer._dom && renderer._dom.document) {
      return renderer._dom.document;
    } else {
      throw new Error('Could not get DOM');
    }
  }
  function getParentView(el) {
    return closest(el, '.ember-view');
  }

  // taken from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
  function closest(el, selector) {
    do {
      if (el.matches(selector)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  }
  function getDestinationElement(context) {
    let dom = getDOM(context);
    const id = 'ember-bootstrap-wormhole';
    let destinationElement = findElementById(dom, id) || findElemementByIdInShadowDom(context, id);
    if (false /* DEBUG */ && !destinationElement) {
      let config = (0, _application.getOwner)(context).resolveRegistration('config:environment');
      if (config.environment === 'test' && typeof FastBoot === 'undefined') {
        let id;
        if (_require.default.has('@ember/test-helpers/dom/get-root-element')) {
          try {
            id = (0, _require.default)('@ember/test-helpers/dom/get-root-element').default().id;
          } catch (ex) {
            // no op
          }
        }
        if (!id) {
          return document.querySelector('#ember-testing');
        }
        return document.getElementById(id);
      }
      (false && (0, _debug.warn)(`No wormhole destination element found for component ${context}. If you have set \`insertEmberWormholeElementToDom\` to false, you should insert a \`div#ember-bootstrap-wormhole\` manually!`, false, {
        id: 'ember-bootstrap.no-destination-element'
      }));
    }
    return destinationElement;
  }
  function findElemementByIdInShadowDom(context, id) {
    const owner = (0, _application.getOwner)(context);
    return owner.rootElement.querySelector && owner.rootElement.querySelector(`[id="${id}"]`);
  }
});