define("ember-bootstrap/components/bs-tooltip/element", ["exports", "@ember/component", "@ember/template-factory", "ember-bootstrap/components/bs-contextual-help/element"], function (_exports, _component, _templateFactory, _element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmberPopper
    @ariaRole="tooltip"
    @placement={{this.placement}}
    @renderInPlace={{@renderInPlace}}
    @popperTarget={{@popperTarget}}
    @modifiers={{this.popperModifiers}}
    @popperContainer={{@destinationElement}}
    @onCreate={{this.updatePlacement}}
    @onUpdate={{this.updatePlacement}}
    @class="tooltip {{@class}} {{if this.fade "fade"}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) this.actualPlacementClass}} {{if (macroCondition (macroGetOwnConfig "isBS3")) this.actualPlacement}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if this.showHelp "show")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.showHelp "in")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "ember-bootstrap-tooltip"}}"
  
    {{create-ref "popperElement"}}
    ...attributes
  >
    <div class={{this.arrowClass}}></div>
    <div class="tooltip-inner">
      {{yield}}
    </div>
  </EmberPopper>
  
  */
  {
    "id": "5YNTi9Nv",
    "block": "[[[8,[39,0],[[17,1],[4,[38,2],[\"popperElement\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[30,0]]]]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@class\"],[\"tooltip\",[30,0,[\"placement\"]],[30,2],[30,3],[30,0,[\"popperModifiers\"]],[30,4],[30,0,[\"updatePlacement\"]],[30,0,[\"updatePlacement\"]],[29,[\"tooltip \",[30,5],\" \",[52,[30,0,[\"fade\"]],\"fade\"],\" \",[30,0,[\"actualPlacementClass\"]],\" \",[27],\" \",[52,[30,0,[\"showHelp\"]],\"show\"],\" \",[27],\" \",[27]]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"arrowClass\"]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"tooltip-inner\"],[12],[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@renderInPlace\",\"@popperTarget\",\"@destinationElement\",\"@class\",\"&default\"],false,[\"ember-popper\",\"if\",\"create-ref\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-tooltip/element.hbs",
    "isStrictMode": false
  });
  /**
   Internal component for tooltip's markup. Should not be used directly.
  
   @class TooltipElement
   @namespace Components
   @extends Components.ContextualHelpElement
   @private
   */
  class TooltipElement extends _element.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "arrowClass", 'arrow');
      _defineProperty(this, "placementClassPrefix", 'bs-tooltip-');
    }
  }
  _exports.default = TooltipElement;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TooltipElement);
});