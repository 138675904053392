define("ember-ref-bucket/utils/ref", ["exports", "@ember/destroyable", "@glimmer/tracking"], function (_exports, _destroyable, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bucketFor = bucketFor;
  _exports.getNodeDestructors = getNodeDestructors;
  _exports.registerNodeDestructor = registerNodeDestructor;
  _exports.resolveGlobalRef = resolveGlobalRef;
  _exports.setGlobalRef = setGlobalRef;
  _exports.unregisterNodeDestructor = unregisterNodeDestructor;
  _exports.watchFor = watchFor;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let lastGlobalRef = null;
  const buckets = new WeakMap();
  const nodeDestructors = new WeakMap();
  let FieldCell = (_class = class FieldCell {
    constructor() {
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
  function setGlobalRef(value) {
    lastGlobalRef = value;
  }
  function resolveGlobalRef() {
    return lastGlobalRef;
  }
  function createBucket() {
    return {
      bucket: {},
      keys: {},
      createTrackedCell(key) {
        if (!(key in this.keys)) {
          this.keys[key] = new FieldCell();
        }
      },
      get(name) {
        this.createTrackedCell(name);
        return this.bucket[name] || null;
      },
      dirtyTrackedCell(name) {
        this.createTrackedCell(name);
        const val = this.keys[name].value;
        this.keys[name].value = val;
      },
      getTracked(name) {
        this.createTrackedCell(name);
        return this.keys[name].value;
      },
      add(name, value) {
        this.createTrackedCell(name);
        this.keys[name].value = value;
        this.bucket[name] = value;
        if (!(name in this.notificationsFor)) {
          this.notificationsFor[name] = [];
        }
        this.notificationsFor[name].forEach(fn => fn());
      },
      addNotificationFor(name, fn) {
        if (!(name in this.notificationsFor)) {
          this.notificationsFor[name] = [];
        }
        this.notificationsFor[name].push(fn);
        return () => {
          this.notificationsFor[name] = this.notificationsFor[name].filter(cb => cb !== cb);
        };
      },
      notificationsFor: {}
    };
  }
  function getNodeDestructors(node) {
    return nodeDestructors.get(node) || [];
  }
  function registerNodeDestructor(node, cb) {
    if (!nodeDestructors.has(node)) {
      nodeDestructors.set(node, []);
    }
    nodeDestructors.get(node).push(cb);
  }
  function unregisterNodeDestructor(node, cb) {
    const destructors = nodeDestructors.get(node) || [];
    nodeDestructors.set(node, destructors.filter(el => el !== cb));
  }
  function bucketFor(rawCtx) {
    const ctx = rawCtx;
    if (!buckets.has(ctx)) {
      buckets.set(ctx, createBucket());
      if ((0, _destroyable.isDestroyed)(ctx) || (0, _destroyable.isDestroying)(ctx)) {
        try {
          return buckets.get(ctx);
        } finally {
          buckets.delete(ctx);
        }
      }
      (0, _destroyable.registerDestructor)(ctx, () => {
        buckets.delete(ctx);
      });
    }
    return buckets.get(ctx);
  }
  function watchFor(name, bucketRef, cb) {
    const bucket = bucketFor(bucketRef);
    return bucket.addNotificationFor(name, cb);
  }
});