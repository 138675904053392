define("ember-bootstrap/components/bs-form/element/legend", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "ember-bootstrap/components/bs-form/element/label", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _label, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <legend class="{{if this.invisibleLabel (if (macroCondition (macroGetOwnConfig "isBS5")) "visually-hidden" "sr-only")}} {{this.labelClass}} {{if this.isHorizontalAndNotCheckbox "col-form-label"}} {{this.sizeClass}}">
    {{#if (has-block)}}
      {{yield}}
    {{/if}}
    {{@label}}
  </legend>
  */
  {
    "id": "4C3n5Jsf",
    "block": "[[[10,\"legend\"],[15,0,[29,[[52,[30,0,[\"invisibleLabel\"]],\"sr-only\"],\" \",[30,0,[\"labelClass\"]],\" \",[52,[30,0,[\"isHorizontalAndNotCheckbox\"]],\"col-form-label\"],\" \",[30,0,[\"sizeClass\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[30,1]],[1,\"\\n\"],[13]],[\"@label\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/legend.hbs",
    "isStrictMode": false
  });
  let FormElementLegend = (_dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = class FormElementLegend extends _label.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, '__ember-bootstrap_subclass', true);
    }
  }) || _class) || _class);
  _exports.default = FormElementLegend;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementLegend);
});