define("ember-intl/services/intl", ["exports", "@ember/application", "@ember/object", "@ember/object/evented", "@ember/debug", "@ember/array", "@ember/service", "@ember/runloop", "ember-intl/-private/formatters", "ember-intl/-private/utils/is-array-equal", "ember-intl/-private/utils/normalize-locale", "ember-intl/-private/utils/get-dom", "ember-intl/-private/utils/hydrate", "ember-intl/-private/store/container"], function (_exports, _application, _object, _evented, _debug, _array, _service, _runloop, _formatters, _isArrayEqual, _normalizeLocale, _getDom, _hydrate, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  var _default = _service.default.extend(_evented.default, {
    /** @public **/
    formats: null,
    /**
     * Returns an array of registered locale names
     *
     * @property locales
     * @public
     */
    locales: _object.computed.readOnly('_translationContainer.locales'),
    /** @public **/
    locale: (0, _object.computed)('_locale', {
      set(_, localeName) {
        const proposed = (0, _array.makeArray)(localeName).map(_normalizeLocale.default);
        if (!(0, _isArrayEqual.default)(proposed, this._locale)) {
          (0, _object.set)(this, '_locale', proposed);
          (0, _runloop.cancel)(this._timer);
          this._timer = (0, _runloop.next)(() => {
            this.trigger('localeChanged');
            this._updateDocumentLanguage(this._locale);
          });
        }
        return this._locale;
      },
      get() {
        return (0, _object.get)(this, '_locale');
      }
    }),
    /**
     * Returns the first locale of the currently active locales
     *
     * @property primaryLocale
     * @public
     */
    primaryLocale: _object.computed.readOnly('locale.0'),
    /** @public **/
    formatRelative: createFormatterProxy('relative'),
    /** @public **/
    formatMessage: createFormatterProxy('message'),
    /** @public **/
    formatNumber: createFormatterProxy('number'),
    /** @public **/
    formatTime: createFormatterProxy('time'),
    /** @public **/
    formatDate: createFormatterProxy('date'),
    /** @private **/
    _translationContainer: null,
    /** @private **/
    _locale: null,
    /** @private **/
    _timer: null,
    /** @private **/
    _formatters: null,
    /** @public **/
    init() {
      this._super(...arguments);
      const initialLocale = (0, _object.get)(this, 'locale') || ['en-us'];
      this.setLocale(initialLocale);
      this._owner = (0, _application.getOwner)(this);
      // Below issue can be ignored, as this is during the `init()` constructor.
      // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
      this._translationContainer = _container.default.create();
      this._formatters = this._createFormatters();
      if (!this.formats) {
        this.formats = this._owner.resolveRegistration('formats:main') || {};
      }
      (0, _hydrate.default)(this);
    },
    willDestroy() {
      this._super(...arguments);
      (0, _runloop.cancel)(this._timer);
    },
    /** @private **/
    onError(_ref) {
      let {
        /* kind, */error
      } = _ref;
      throw error;
    },
    /** @public **/
    lookup(key, localeName) {
      const localeNames = this._localeWithDefault(localeName);
      let translation;
      for (let i = 0; i < localeNames.length; i++) {
        translation = this._translationContainer.lookup(localeNames[i], key);
        if (translation !== undefined) {
          break;
        }
      }
      return translation;
    },
    /** @private **/
    lookupAst(key, localeName) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const localeNames = this._localeWithDefault(localeName);
      let translation;
      for (let i = 0; i < localeNames.length; i++) {
        translation = this._translationContainer.lookupAst(localeNames[i], key);
        if (translation !== undefined) {
          break;
        }
      }
      if (translation === undefined && options.resilient !== true) {
        const missingMessage = this._owner.resolveRegistration('util:intl/missing-message');
        return missingMessage.call(this, key, localeNames, options);
      }
      return translation;
    },
    validateKeys(keys) {
      return keys.forEach(key => {
        (false && !(typeof key === 'string') && (0, _debug.assert)(`[ember-intl] expected translation key "${key}" to be of type String but received: "${typeof key}"`, typeof key === 'string'));
      });
    },
    /** @public **/
    t(key) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let keys = [key];
      if (options.default) {
        if (Array.isArray(options.default)) {
          keys = [...keys, ...options.default];
        } else if (typeof options.default === 'string') {
          keys = [...keys, options.default];
        }
      }
      this.validateKeys(keys);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        const ast = this.lookupAst(key, options.locale, {
          ...options,
          // Note: last iteration will throw with the last key that was missing
          // in the future maybe the thrown error should include all the keys to help debugging
          resilient: keys.length - 1 !== index
        });
        if (ast) {
          return this.formatMessage(ast, options);
        }
      }
    },
    /** @public **/
    exists(key, localeName) {
      const localeNames = this._localeWithDefault(localeName);
      (false && !(Array.isArray(localeNames) && localeNames.length) && (0, _debug.assert)(`[ember-intl] locale is unset, cannot lookup '${key}'`, Array.isArray(localeNames) && localeNames.length));
      return localeNames.some(localeName => this._translationContainer.has(localeName, key));
    },
    /** @public */
    setLocale(locale) {
      (0, _object.set)(this, 'locale', locale);
    },
    /** @public **/
    addTranslations(localeName, payload) {
      this._translationContainer.push((0, _normalizeLocale.default)(localeName), payload);
    },
    /** @public **/
    translationsFor(localeName) {
      return this._translationContainer.findTranslationModel((0, _normalizeLocale.default)(localeName), false);
    },
    /** @private **/
    _localeWithDefault(localeName) {
      if (!localeName) {
        return (0, _object.get)(this, '_locale') || [];
      }
      if (typeof localeName === 'string') {
        return (0, _array.makeArray)(localeName).map(_normalizeLocale.default);
      }
      if (Array.isArray(localeName)) {
        return localeName.map(_normalizeLocale.default);
      }
    },
    /** @private **/
    _updateDocumentLanguage(locales) {
      const dom = (0, _getDom.default)(this);
      if (dom) {
        const [primaryLocale] = locales;
        const html = dom.documentElement;
        html.setAttribute('lang', primaryLocale);
      }
    },
    /** @private */
    _createFormatters() {
      const formatterConfig = {
        onError: this.onError.bind(this),
        readFormatConfig: () => this.formats
      };
      return {
        message: new _formatters.FormatMessage(formatterConfig),
        relative: new _formatters.FormatRelative(formatterConfig),
        number: new _formatters.FormatNumber(formatterConfig),
        time: new _formatters.FormatTime(formatterConfig),
        date: new _formatters.FormatDate(formatterConfig)
      };
    }
  });
  _exports.default = _default;
  function createFormatterProxy(name) {
    return function serviceFormatterProxy(value, formatOptions) {
      let locale;
      if (formatOptions && formatOptions.locale) {
        locale = this._localeWithDefault(formatOptions.locale);
      } else {
        locale = (0, _object.get)(this, 'locale');
      }
      return this._formatters[name].format(locale, value, formatOptions);
    };
  }
});