define("ember-bootstrap/utils/cp/overrideable", ["exports", "@ember/object", "@ember/debug"], function (_exports, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = overrideableCP;
  /**
   * CP macro that created a regular computed property, which can be manually overriden.
   * This is needed after implicitly overrideable CPs have been deprecated:
   * https://deprecations-app-prod.herokuapp.com/deprecations/v3.x/#toc_computed-property-override
   *
   * @private
   */
  function overrideableCP() {
    let fn = Array.prototype.slice.call(arguments, -1)[0];
    let args = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
    (false && !(typeof fn === 'function') && (0, _debug.assert)('Last argument for overrideableCP must be a function', typeof fn === 'function'));
    return (0, _object.computed)(...args, {
      get(key) {
        let overridden = this[`__${key}`];
        return overridden || fn.call(this);
      },
      set(key, value) {
        this[`__${key}`] = value;
        return value;
      }
    });
  }
});