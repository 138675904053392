define("ember-resources/-private/resources/simple", ["exports", "@glimmer/tracking/primitives/cache", "@ember/application", "@ember/destroyable", "@ember/helper"], function (_exports, _cache, _application, _destroyable, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Resource = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } // typed-ember has not published types for this yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // typed-ember has not published types for this yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  class Resource {
    static next(prev, args) {
      // TS does not infer subclass static types
      return new this((0, _application.getOwner)(prev), args, prev);
    }

    /**
     * @param {unknown} [owner] the application owner which allows service injections
     * @param {T} [args] both positional (array) and named (object) args
     * @param {Resource<T>} [previous] if the resource is being updated, this value will be the previous instance of the resource
     *
     */
    constructor(owner, args, /* eslint-disable @typescript-eslint/no-unused-vars */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    previous) {
      this.args = args;
      (0, _application.setOwner)(this, owner);
    }
  }
  _exports.Resource = Resource;
  class ResourceManager {
    constructor(owner) {
      _defineProperty(this, "capabilities", (0, _helper.capabilities)('3.23', {
        hasValue: true,
        hasDestroyable: true
      }));
      this.owner = owner;
    }
    createHelper(Class, args) {
      let owner = this.owner;
      let instance;
      let cache = (0, _cache.createCache)(() => {
        let newInstance = new Class(owner, args, instance);
        (0, _destroyable.associateDestroyableChild)(cache, newInstance);
        if (instance) (0, _destroyable.destroy)(instance);
        instance = newInstance;
        return instance;
      });
      return cache;
    }
    getValue(cache) {
      let instance = (0, _cache.getValue)(cache);
      return instance;
    }
    getDestroyable(cache) {
      return cache;
    }
  }
  (0, _helper.setHelperManager)(owner => new ResourceManager(owner), Resource);
});