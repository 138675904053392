define("@fortawesome/ember-fontawesome/components/fa-icon", ["exports", "@ember/component", "@fortawesome/ember-fontawesome/templates/components/fa-icon", "@fortawesome/fontawesome-svg-core", "@ember/template", "@ember/object", "@ember/polyfills", "ember-get-config", "@ember/debug"], function (_exports, _component, _faIcon, _fontawesomeSvgCore, _template, _object, _polyfills, _emberGetConfig, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*eslint no-console: ["error", { allow: ["warn"] }] */

  function getWithDefault(object, key, defaultValue) {
    let value = (0, _object.get)(object, key);
    if (value === undefined) {
      return defaultValue;
    }
    return value;
  }
  function getConfigOption(key, defaultValue) {
    return getWithDefault(_emberGetConfig.default, `fontawesome.${key}`, defaultValue);
  }
  function objectWithKey(key, value) {
    return Array.isArray(value) && value.length > 0 || !Array.isArray(value) && value ? {
      [key]: value
    } : {};
  }
  function classList() {
    let classes = {
      'fa-spin': this.spin,
      'fa-pulse': this.pulse,
      'fa-fw': this.fixedWidth,
      'fa-border': this.border,
      'fa-li': this.listItem,
      'fa-flip-horizontal': this.flip === 'horizontal' || this.flip === 'both',
      'fa-flip-vertical': this.flip === 'vertical' || this.flip === 'both',
      [`fa-${this.size}`]: getWithDefault(this, 'size', null) !== null,
      [`fa-rotate-${this.rotation}`]: getWithDefault(this, 'rotation', null) !== null,
      [`fa-pull-${this.pull}`]: getWithDefault(this, 'pull', null) !== null
    };
    return Object.keys(classes).map(key => classes[key] ? key : null).filter(key => key);
  }
  function normalizeIconArgs(prefix, icon) {
    const defaultPrefix = getConfigOption('defaultPrefix', 'fas');
    if (!icon) {
      return {
        prefix: defaultPrefix,
        iconName: null
      };
    }
    if (typeof icon === 'object' && icon.prefix && icon.iconName) {
      return icon;
    }
    if (typeof prefix === 'string' && typeof icon === 'string') {
      return {
        prefix: prefix,
        iconName: icon
      };
    }
    if (typeof icon === 'string') {
      return {
        prefix: defaultPrefix,
        iconName: icon
      };
    }
  }
  const IconComponent = _component.default.extend({
    init() {
      this._super(...arguments);
      if (this.params && this.params.length) {
        (false && !(false) && (0, _debug.deprecate)('Passing the icon as a position param is deprecated and will be removed in v1.0.0.', false, {
          id: '@fortawesome/ember-fontawesome.no-positional-params',
          until: '1.0.0',
          url: 'https://github.com/FortAwesome/ember-fontawesome#template'
        }));
      }
    },
    layout: _faIcon.default,
    tagName: 'svg',
    classNameBindings: ['allClasses'],
    attributeBindings: [
    // attributes watched for mutation
    'data-prefix', 'data-icon', 'data-fa-transform', 'data-fa-mask', 'data-fa-processed',
    // accessibility attributes
    'aria-hidden', 'aria-labelledby', 'focusable',
    // svg attributes
    'role', 'xmlns', 'viewBox', 'safeStyle:style'],
    html: (0, _object.computed)('abstractIcon.children.[]', function () {
      const abstractIcon = this.abstractIcon;
      let newHtml;
      if (!abstractIcon) {
        newHtml = (0, _template.htmlSafe)('');
      } else {
        newHtml = (0, _template.htmlSafe)(abstractIcon.children.reduce((acc, cur) => {
          return `${acc}${(0, _fontawesomeSvgCore.toHtml)(cur)}`;
        }, ''));
      }
      return newHtml;
    }),
    safeStyle: (0, _object.computed)('attributes', function () {
      const attributes = this.attributes;
      const style = getWithDefault(attributes, 'style');
      return style ? (0, _template.htmlSafe)(`${style}`) : undefined;
    }),
    iconOrPositionalParam: (0, _object.computed)('icon', 'params.[]', function () {
      if (this.icon) {
        return this.icon;
      }
      if (this.params && this.params.length) {
        return this.params[0];
      }
      return null;
    }),
    abstractIcon: (0, _object.computed)('border', 'fixedWidth', 'flip', 'icon', 'iconOrPositionalParam', 'listItem', 'mask', 'prefix', 'pull', 'pulse', 'rotation', 'size', 'spin', 'symbol', 'title', 'transform', function () {
      const iconLookup = normalizeIconArgs(this.prefix, this.iconOrPositionalParam);
      const classes = objectWithKey('classes', [...classList.bind(this)()]);
      const transformProp = this.transform;
      const transform = objectWithKey('transform', typeof transformProp === 'string' ? _fontawesomeSvgCore.parse.transform(transformProp) : transformProp);
      const mask = objectWithKey('mask', normalizeIconArgs(null, this.mask));
      const symbol = getWithDefault(this, 'symbol', false);
      let title = getWithDefault(this, 'title', null);
      if (title) {
        title = `${title}`;
      }
      const o = (0, _polyfills.assign)({}, classes, transform, mask, {
        symbol,
        title
      });
      const renderedIcon = (0, _fontawesomeSvgCore.icon)(iconLookup, o);
      if (!renderedIcon) {
        console.warn(`Could not find icon: iconName=${iconLookup.iconName}, prefix=${iconLookup.prefix}`);
        return null;
      }
      return renderedIcon.abstract[0];
    }),
    attributes: (0, _object.computed)('abstractIcon.attributes', function () {
      const abstractIcon = this.abstractIcon;
      return abstractIcon ? abstractIcon.attributes : {};
    }),
    allClasses: (0, _object.computed)('abstractIcon', 'attributes.class', 'class', function () {
      const abstractIcon = this.abstractIcon;
      if (!abstractIcon) {
        return _fontawesomeSvgCore.config.replacementClass;
      }
      const attributes = this.attributes;
      const iconClasses = getWithDefault(attributes, 'class');
      return iconClasses;
    }),
    'data-prefix': (0, _object.computed)('attributes.data-prefix', function () {
      return getWithDefault(this.attributes, 'data-prefix');
    }),
    'data-icon': (0, _object.computed)('attributes.data-icon', function () {
      return getWithDefault(this.attributes, 'data-icon');
    }),
    'data-fa-transform': (0, _object.computed)('attributes.data-fa-transform', function () {
      return getWithDefault(this.attributes, 'data-fa-transform');
    }),
    'data-fa-mask': (0, _object.computed)('attributes.data-fa-mask', function () {
      return getWithDefault(this.attributes, 'data-fa-mask');
    }),
    'data-fa-processed': (0, _object.computed)('attributes.data-fa-processed', function () {
      return getWithDefault(this.attributes, 'data-fa-processed');
    }),
    'aria-hidden': (0, _object.computed)('attributes.aria-hidden', function () {
      return getWithDefault(this.attributes, 'aria-hidden');
    }),
    'aria-labelledby': (0, _object.computed)('attributes.aria-labelledby', function () {
      return getWithDefault(this.attributes, 'aria-labelledby');
    }),
    'focusable': (0, _object.computed)('attributes.focusable', function () {
      return getWithDefault(this.attributes, 'focusable');
    }),
    'role': (0, _object.computed)('attributes.role', function () {
      return getWithDefault(this.attributes, 'role');
    }),
    'xmlns': (0, _object.computed)('attributes.xmlns', function () {
      return getWithDefault(this.attributes, 'xmlns');
    }),
    'viewBox': (0, _object.computed)('abstractIcon', 'attributes.viewBox', function () {
      const abstractIcon = this.abstractIcon;
      if (!abstractIcon) {
        return '0 0 448 512';
      }
      return getWithDefault(this.attributes, 'viewBox');
    })
  });

  // Enables {{fa-icon 'iconnamehere'}} syntax, while still allowing {{fa-icon icon='iconnamehere'}}
  IconComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = IconComponent;
  _exports.default = _default;
});