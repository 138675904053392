define("@sentry/ember/index", ["exports", "@sentry/browser", "@ember/runloop", "@ember/debug", "ember", "@sentry/utils"], function (_exports, Sentry, _runloop, _debug, _ember, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    InitSentryForEmber: true,
    getActiveTransaction: true,
    instrumentRoutePerformance: true,
    init: true
  };
  _exports.InitSentryForEmber = InitSentryForEmber;
  _exports.instrumentRoutePerformance = _exports.init = _exports.getActiveTransaction = void 0;
  Object.keys(Sentry).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === Sentry[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return Sentry[key];
      }
    });
  });
  function _getSentryInitConfig() {
    var _global$__sentryEmber;
    const _global = (0, _utils.getGlobalObject)();
    _global.__sentryEmberConfig = (_global$__sentryEmber = _global.__sentryEmberConfig) !== null && _global$__sentryEmber !== void 0 ? _global$__sentryEmber : {};
    return _global.__sentryEmberConfig;
  }
  function InitSentryForEmber(_runtimeConfig) {
    const environmentConfig = {
      "disableInstrumentComponents": true,
      "disablePerformance": true,
      "disableRunloopPerformance": true,
      "sentry": {
        "attachStacktrace": true,
        "autoSessionTracking": false,
        "dsn": "https://f156100c0d60472c831a751a8d9c7ac6@o342082.ingest.sentry.io/5982099",
        "enabled": true,
        "environment": "production",
        "tracesSampleRate": 1
      }
    };
    (false && !(environmentConfig) && (0, _debug.assert)('Missing configuration.', environmentConfig));
    (false && !(environmentConfig.sentry || _runtimeConfig) && (0, _debug.assert)('Missing configuration for Sentry.', environmentConfig.sentry || _runtimeConfig));
    if (!environmentConfig.sentry) {
      // If environment config is not specified but the above assertion passes, use runtime config.
      environmentConfig.sentry = {
        ..._runtimeConfig
      };
    }

    // Merge runtime config into environment config, preferring runtime.
    Object.assign(environmentConfig.sentry, _runtimeConfig || {});
    const initConfig = Object.assign({}, environmentConfig.sentry);
    initConfig._metadata = initConfig._metadata || {};
    initConfig._metadata.sdk = {
      name: 'sentry.javascript.ember',
      packages: [{
        name: 'npm:@sentry/ember',
        version: Sentry.SDK_VERSION
      }],
      version: Sentry.SDK_VERSION
    };

    // Persist Sentry init options so they are identical when performance initializers call init again.
    const sentryInitConfig = _getSentryInitConfig();
    Object.assign(sentryInitConfig, initConfig);
    Sentry.init(initConfig);
  }
  const getActiveTransaction = () => {
    var _Sentry$getCurrentHub, _Sentry$getCurrentHub2;
    return (_Sentry$getCurrentHub = Sentry.getCurrentHub()) === null || _Sentry$getCurrentHub === void 0 ? void 0 : (_Sentry$getCurrentHub2 = _Sentry$getCurrentHub.getScope()) === null || _Sentry$getCurrentHub2 === void 0 ? void 0 : _Sentry$getCurrentHub2.getTransaction();
  };
  _exports.getActiveTransaction = getActiveTransaction;
  const instrumentRoutePerformance = BaseRoute => {
    const instrumentFunction = async (op, description, fn, args) => {
      const startTimestamp = (0, _utils.timestampWithMs)();
      const result = await fn(...args);
      const currentTransaction = getActiveTransaction();
      if (!currentTransaction) {
        return result;
      }
      currentTransaction.startChild({
        op,
        description,
        startTimestamp
      }).finish();
      return result;
    };
    return {
      [BaseRoute.name]: class extends BaseRoute {
        beforeModel() {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }
          return instrumentFunction('ember.route.beforeModel', this.fullRouteName, super.beforeModel.bind(this), args);
        }
        async model() {
          for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            args[_key2] = arguments[_key2];
          }
          return instrumentFunction('ember.route.model', this.fullRouteName, super.model.bind(this), args);
        }
        async afterModel() {
          for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
            args[_key3] = arguments[_key3];
          }
          return instrumentFunction('ember.route.afterModel', this.fullRouteName, super.afterModel.bind(this), args);
        }
        async setupController() {
          for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
            args[_key4] = arguments[_key4];
          }
          return instrumentFunction('ember.route.setupController', this.fullRouteName, super.setupController.bind(this), args);
        }
      }
    }[BaseRoute.name];
  };
  _exports.instrumentRoutePerformance = instrumentRoutePerformance;
  // init is now the preferred way to call initialization for this addon.
  const init = InitSentryForEmber;
  _exports.init = init;
});