define("ember-bootstrap/components/bs-form/element/layout/vertical", ["exports", "@ember/component", "@ember/template-factory", "ember-bootstrap/components/bs-form/element/layout"], function (_exports, _component, _templateFactory, _layout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasLabel}}
    <@labelComponent/>
  {{/if}}
  {{yield}}
  {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    <@feedbackIconComponent/>
  {{/if}}
  <@errorsComponent/>
  <@helpTextComponent/>
  */
  {
    "id": "aH4GG1sn",
    "block": "[[[41,[30,0,[\"hasLabel\"]],[[[1,\"  \"],[8,[30,1],null,null,null],[1,\"\\n\"]],[]],null],[18,4,null],[1,\"\\n\"],[8,[30,2],null,null,null],[1,\"\\n\"],[8,[30,3],null,null,null]],[\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/layout/vertical.hbs",
    "isStrictMode": false
  });
  /**
  
   @class FormElementLayoutVertical
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  class FormElementLayoutVertical extends _layout.default {}
  _exports.default = FormElementLayoutVertical;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementLayoutVertical);
});