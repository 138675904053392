define("ember-bootstrap/helpers/bs-noop", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bsNoop = bsNoop;
  _exports.default = void 0;
  const noop = () => {};
  function bsNoop() {
    return noop;
  }
  var _default = (0, _helper.helper)(bsNoop);
  _exports.default = _default;
});