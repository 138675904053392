define("ember-resources/index", ["exports", "ember-resources/-private/resources/lifecycle", "ember-resources/-private/resources/simple", "ember-resources/-private/ember-concurrency", "ember-resources/-private/use-function", "ember-resources/-private/use-helper", "ember-resources/-private/use-resource"], function (_exports, _lifecycle, _simple, _emberConcurrency, _useFunction, _useHelper, _useResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "LifecycleResource", {
    enumerable: true,
    get: function () {
      return _lifecycle.LifecycleResource;
    }
  });
  Object.defineProperty(_exports, "Resource", {
    enumerable: true,
    get: function () {
      return _simple.Resource;
    }
  });
  Object.defineProperty(_exports, "useFunction", {
    enumerable: true,
    get: function () {
      return _useFunction.useFunction;
    }
  });
  Object.defineProperty(_exports, "useHelper", {
    enumerable: true,
    get: function () {
      return _useHelper.useHelper;
    }
  });
  Object.defineProperty(_exports, "useResource", {
    enumerable: true,
    get: function () {
      return _useResource.useResource;
    }
  });
  Object.defineProperty(_exports, "useTask", {
    enumerable: true,
    get: function () {
      return _emberConcurrency.useTask;
    }
  });
});