define("ember-resources/-private/ember-concurrency", ["exports", "@glimmer/tracking/primitives/cache", "@ember/debug", "@ember/helper", "ember-resources/-private/resources/ember-concurrency-task", "ember-resources/-private/utils"], function (_exports, _cache, _debug, _helper, _emberConcurrencyTask, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.proxyClass = proxyClass;
  _exports.useTask = useTask;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /* eslint-disable @typescript-eslint/no-explicit-any */ /* eslint-disable @typescript-eslint/ban-types */ // typed-ember has not publihsed types for this yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // typed-ember has not publihsed types for this yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function useTask(context, task, thunk) {
    (false && !('perform' in task) && (0, _debug.assert)(`Task does not have a perform method. Is it actually a task?`, 'perform' in task));
    let target = buildUnproxiedTaskResource(context, task, thunk || _utils.DEFAULT_THUNK);

    // TS can't figure out what the proxy is doing
    return proxyClass(target);
  }
  const TASK_CACHE = new WeakMap();
  function buildUnproxiedTaskResource(context, task, thunk) {
    let resource;
    let klass;
    let existing = TASK_CACHE.get(task);
    if (existing) {
      klass = existing;
    } else {
      klass = class AnonymousTaskRunner extends _emberConcurrencyTask.TaskResource {
        constructor() {
          super(...arguments);
          _defineProperty(this, _emberConcurrencyTask.TASK, task);
        }
      };
      TASK_CACHE.set(task, klass);
    }
    return {
      get value() {
        if (!resource) {
          resource = (0, _helper.invokeHelper)(context, klass, () => {
            return (0, _utils.normalizeThunk)(thunk);
          });
        }
        return (0, _cache.getValue)(resource);
      }
    };
  }
  function proxyClass(target) {
    return new Proxy(target, {
      get(target, key) {
        const taskRunner = target.value;
        if (key === 'value') {
          /**
           * getter than fallsback to the previous task's value
           */
          return taskRunner.value;
        }
        const instance = taskRunner.currentTask;
        const value = Reflect.get(instance, key, instance);
        return typeof value === 'function' ? value.bind(instance) : value;
      },
      ownKeys(target) {
        return Reflect.ownKeys(target.value);
      },
      getOwnPropertyDescriptor(target, key) {
        return Reflect.getOwnPropertyDescriptor(target.value, key);
      }
    });
  }
});