define("ember-bootstrap/components/bs-form/element/layout/horizontal", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/debug", "@ember/utils", "ember-bootstrap/components/bs-form/element/layout", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _templateFactory, _object, _debug, _utils, _layout, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasLabel}}
    <@labelComponent @labelClass={{@horizontalLabelGridClass}}/>
    <div class={{this.horizontalInputGridClass}}>
      {{yield}}
      {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
        <@feedbackIconComponent/>
      {{/if}}
      <@errorsComponent/>
      <@helpTextComponent/>
    </div>
  {{else}}
    <div class="{{this.horizontalInputGridClass}} {{this.horizontalInputOffsetGridClass}}">
      {{yield}}
      {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
        <@feedbackIconComponent/>
      {{/if}}
      <@errorsComponent/>
      <@helpTextComponent/>
    </div>
  {{/if}}
  */
  {
    "id": "3halle79",
    "block": "[[[41,[30,0,[\"hasLabel\"]],[[[1,\"  \"],[8,[30,1],null,[[\"@labelClass\"],[[30,2]]],null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"horizontalInputGridClass\"]]],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n\"],[1,\"    \"],[8,[30,3],null,null,null],[1,\"\\n    \"],[8,[30,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[29,[[30,0,[\"horizontalInputGridClass\"]],\" \",[30,0,[\"horizontalInputOffsetGridClass\"]]]]],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n\"],[1,\"    \"],[8,[30,3],null,null,null],[1,\"\\n    \"],[8,[30,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@labelComponent\",\"@horizontalLabelGridClass\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/layout/horizontal.hbs",
    "isStrictMode": false
  });
  /**
  
   @class FormElementLayoutHorizontal
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  let FormElementLayoutHorizontal = (_dec = (0, _object.computed)('horizontalLabelGridClass').readOnly(), _dec2 = (0, _object.computed)('horizontalLabelGridClass'), (_class = class FormElementLayoutHorizontal extends _layout.default {
    constructor() {
      super(...arguments);
      /**
       * The Bootstrap grid class for form labels within a horizontal layout form.
       *
       * @property horizontalLabelGridClass
       * @type string
       * @public
       */
      _initializerDefineProperty(this, "horizontalLabelGridClass", _descriptor, this);
    }
    /**
     * Computed property that specifies the Bootstrap grid class for form controls within a horizontal layout form.
     *
     * @property horizontalInputGridClass
     * @type string
     * @readonly
     * @private
     */
    get horizontalInputGridClass() {
      if ((0, _utils.isBlank)(this.horizontalLabelGridClass)) {
        return undefined;
      }
      let parts = this.horizontalLabelGridClass.split('-');
      (false && !(parts.length === 3) && (0, _debug.assert)('horizontalInputGridClass must match format bootstrap grid column class', parts.length === 3));
      parts[2] = 12 - parts[2];
      return parts.join('-');
    }

    /**
     * Computed property that specifies the Bootstrap offset grid class for form controls within a horizontal layout
     * form, that have no label.
     *
     * @property horizontalInputOffsetGridClass
     * @type string
     * @readonly
     * @private
     */
    get horizontalInputOffsetGridClass() {
      if ((0, _utils.isBlank)(this.horizontalLabelGridClass)) {
        return undefined;
      }
      let parts = this.horizontalLabelGridClass.split('-');
      {
        parts.splice(0, 1, 'offset');
      }
      return parts.join('-');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "horizontalLabelGridClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "horizontalInputGridClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "horizontalInputGridClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "horizontalInputOffsetGridClass", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "horizontalInputOffsetGridClass"), _class.prototype)), _class));
  _exports.default = FormElementLayoutHorizontal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementLayoutHorizontal);
});