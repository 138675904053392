define("ember-ref-bucket/index", ["exports", "ember-ref-bucket/utils/ref", "@ember/application"], function (_exports, _ref, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.globalRef = globalRef;
  _exports.nodeFor = nodeFor;
  _exports.ref = ref;
  Object.defineProperty(_exports, "registerNodeDestructor", {
    enumerable: true,
    get: function () {
      return _ref.registerNodeDestructor;
    }
  });
  _exports.trackedGlobalRef = trackedGlobalRef;
  _exports.trackedRef = trackedRef;
  Object.defineProperty(_exports, "unregisterNodeDestructor", {
    enumerable: true,
    get: function () {
      return _ref.unregisterNodeDestructor;
    }
  });
  function nodeFor(context, name) {
    return (0, _ref.bucketFor)(context).get(name);
  }
  function maybeReturnCreated(value, createdValues, fn, ctx) {
    if (value === null || value === undefined) {
      return null;
    }
    if (fn) {
      if (!createdValues.has(value)) {
        createdValues.set(value, fn.call(ctx, value));
      }
      return createdValues.get(value);
    } else {
      return value;
    }
  }
  function ref(name, fn) {
    return function () {
      const createdValues = new WeakMap();
      return {
        get() {
          const value = (0, _ref.bucketFor)(this).get(name);
          return maybeReturnCreated(value, createdValues, fn, this);
        }
      };
    };
  }
  function globalRef(name, fn) {
    return function () {
      const createdValues = new WeakMap();
      return {
        get() {
          const value = (0, _ref.bucketFor)((0, _application.getOwner)(this) || (0, _ref.resolveGlobalRef)()).get(name);
          return maybeReturnCreated(value, createdValues, fn, this);
        }
      };
    };
  }
  function trackedRef(name, fn) {
    return function () {
      const createdValues = new WeakMap();
      return {
        get() {
          const value = (0, _ref.bucketFor)(this).getTracked(name);
          return maybeReturnCreated(value, createdValues, fn, this);
        }
      };
    };
  }
  function trackedGlobalRef(name, fn) {
    return function () {
      const createdValues = new WeakMap();
      return {
        get() {
          const value = (0, _ref.bucketFor)((0, _application.getOwner)(this) || (0, _ref.resolveGlobalRef)()).getTracked(name);
          return maybeReturnCreated(value, createdValues, fn, this);
        }
      };
    };
  }
});