define("ember-resources/-private/resources/ember-concurrency-task", ["exports", "@ember/object", "ember-resources/-private/resources/lifecycle"], function (_exports, _object, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaskResource = _exports.TASK = void 0;
  /* eslint-disable ember/no-get */

  /**
   * Need to define this ourselves, because between
   * ember-concurrency 1, 2, -ts, decorators, etc
   * there are 5+ ways the task type is defined
   *
   * https://github.com/machty/ember-concurrency/blob/f53656876748973cf6638f14aab8a5c0776f5bba/addon/index.d.ts#L280
   */

  const TASK = Symbol('TASK');
  _exports.TASK = TASK;
  class TaskResource extends _lifecycle.LifecycleResource {
    // Set via useTask

    // Set during setup/update

    get taskArgs() {
      return this.args.positional;
    }
    get value() {
      // in ember-concurrency@v1, value is not consumable tracked data
      // until the task is resolved, so we need to consume the isRunning
      // property so that value updates
      (0, _object.get)(this.currentTask, 'isRunning');
      return this.currentTask.value ?? this.lastTask?.value;
    }
    setup() {
      this.update();
    }
    update() {
      if (this.currentTask) {
        this.lastTask = this.currentTask;
      }
      this.currentTask = this[TASK].perform(...this.taskArgs);
    }
    teardown() {
      this[TASK].cancelAll();
    }
  }
  _exports.TaskResource = TaskResource;
});