define("ember-bootstrap/utils/decorators/arg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arg;
  function arg(target, key, descriptor) {
    return {
      get() {
        const argValue = this.args[key];
        return argValue !== undefined ? argValue : descriptor.initializer ? descriptor.initializer.call(this) : undefined;
      }
    };
  }
});