define("ember-assign-helper/helpers/assign", ["exports", "@ember/polyfills", "@ember/component/helper"], function (_exports, _polyfills, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assign = assign;
  _exports.default = void 0;
  function assign(params) {
    return (0, _polyfills.assign)({}, ...params);
  }
  var _default = (0, _helper.helper)(assign);
  _exports.default = _default;
});