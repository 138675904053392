define("ember-resources/-private/resources/function-runner", ["exports", "@ember/destroyable", "@ember/object", "@ember/runloop", "@ember/test-waiters", "ember-resources/-private/resources/lifecycle"], function (_exports, _destroyable, _object, _runloop, _testWaiters, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INITIAL_VALUE = _exports.FunctionRunner = _exports.FUNCTION_TO_RUN = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const FUNCTION_TO_RUN = Symbol('FUNCTION TO RUN');
  _exports.FUNCTION_TO_RUN = FUNCTION_TO_RUN;
  const INITIAL_VALUE = Symbol('INITIAL VALUE');
  _exports.INITIAL_VALUE = INITIAL_VALUE;
  const HAS_RUN = Symbol('HAS RUN');
  const SECRET_VALUE = '___ Secret Value ___';

  // type UnwrapAsync<T> = T extends Promise<infer U> ? U : T;
  // type GetReturn<T extends () => unknown> = UnwrapAsync<ReturnType<T>>;
  class FunctionRunner extends _lifecycle.LifecycleResource {
    constructor() {
      super(...arguments);
      // Set when using useResource
      _defineProperty(this, HAS_RUN, false);
    }
    get value() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (0, _object.get)(this, SECRET_VALUE);
      if (!this[HAS_RUN] && this[INITIAL_VALUE]) {
        return this[INITIAL_VALUE];
      }
      return this[SECRET_VALUE];
    }
    get funArgs() {
      return this.args.positional;
    }
    setup() {
      this.update();
    }

    /**
     * NOTE: there is no reliable way to determine if a function is async before the function is ran.
     *   - we can't use fun[Symbol.toStringtag] === 'AsyncFunction' because minifiers may remove the
     *     async keyword
     */
    update() {
      /**
       * NOTE: All positional args are consumed
       */
      for (let i = 0; i < this.funArgs.length; i++) {
        this.funArgs[i];
      }
      const fun = this[FUNCTION_TO_RUN];

      /**
       * Do not access "value" directly in this function. You'll have infinite re-rendering errors
       */
      const previous = this[SECRET_VALUE];
      const asyncWaiter = async () => {
        // in case the async function tries to consume things on the parent `this`,
        // be sure we start with a fresh frame
        await new Promise(resolve => (0, _runloop.schedule)('afterRender', resolve));
        if ((0, _destroyable.isDestroying)(this) || (0, _destroyable.isDestroyed)(this)) {
          return;
        }
        const value = await fun(previous, ...this.funArgs);
        if ((0, _destroyable.isDestroying)(this) || (0, _destroyable.isDestroyed)(this)) {
          return;
        }
        this[SECRET_VALUE] = value;
        this[HAS_RUN] = true;
        (0, _object.notifyPropertyChange)(this, SECRET_VALUE);
      };
      (0, _testWaiters.waitForPromise)(asyncWaiter());

      // If we ever want to bring sync-support back:
      // this[SECRET_VALUE] = fun(previous, ...this.funArgs) as Return;
    }
  }
  _exports.FunctionRunner = FunctionRunner;
});