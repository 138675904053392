define("ember-bootstrap/helpers/bs-size-class", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sizeClassHelper = sizeClassHelper;
  function sizeClassHelper(_ref, _ref2) {
    let [prefix, size] = _ref;
    let {
      default: defaultValue
    } = _ref2;
    size = size ?? defaultValue;
    return (0, _utils.isBlank)(size) ? null : `${prefix}-${size}`;
  }
  var _default = (0, _helper.helper)(sizeClassHelper);
  _exports.default = _default;
});